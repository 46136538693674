<template>
  <div class="d-flex flex-column pt-0 wallet">
    <div
      v-if="loading"
      class="spinner-body"
    >
      <b-spinner />
    </div>
    <div
      v-else
      class="poisiton-relative table-responsive"
    >
      <p-table
        :fields="tableFields"
        :items="items"
        :filter="searchText"
        :current-page="currentPage"
        :per-page="perPage"
        :sort-by="sortBy"
        :sort-desc="isDesc"
        :filter-included-fields="filterIncludedFiedls"
        @filtered="getFilteredItems"
      >
        <template #empty>
          <p class="h2 d-flex table-empty-message justify-content-center align-items-center gap-2">
            <b-icon
              icon="exclamation-circle"
              scale="1"
            />
            <span> No Items Found </span>
          </p>
        </template>
        <template #emptyfiltered>
          <p class="h2 d-flex table-empty-message justify-content-center align-items-center gap-2">
            <b-icon
              icon="exclamation-circle"
              scale="1"
            />
            <span> No Filtered Items </span>
          </p>
        </template>
        <template #cell(_id)="data">
          <span @click="onClickEditTerms(data.item)">{{ data.item._id }}</span>
        </template>
        <template #cell(referralId)="data">
          <span @click="onClickEditTerms(data.item)">{{ data.item.referralId }}</span>
        </template>
        <template #cell(status)="data">
          <span class="text-capitalize">{{ data.item.status }}</span>
        </template>
        <template #cell(paymentTerms)="data">
          <span class="text-nowrap">{{ data.item.paymentTerms }}</span>
        </template>
      </p-table>
    </div>
    <k-table-pagination
      :total-items="itemsCount"
      @emit-current-page="updateCurrentPage"
      @emit-per-page="updatePerPage"
    />
  </div>
</template>

<script>
import { BIcon, BSpinner } from 'bootstrap-vue'
import { PTable, KTablePagination } from '@kingpin-global/kingpin-ui'
import { formattedDate } from '@/@core/utils/format'
import { walletMixin } from '@/mixins/wallet-mixin'
import {
  CLEAR_STATE,
  SET_COMPONENT, SET_COMPONENT_CONTENT, SET_FORM_ACTIONS, SET_SUB_TITLE, SET_TITLE, TOGGLE_SIDEBAR,
} from '@/store/modules/sidebar.module'
import { getWalletFields } from '@/table-fields-constants'
import EditWalletRuleVue from './EditWalletRule.vue'

export default {
  name: 'BrandVsRetailerTerms',
  components: {
    PTable,
    KTablePagination,
    BIcon,
    BSpinner,
  },
  mixins: [walletMixin],
  props: {
    tableData: {
      type: Array,
      default() {
        return []
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    searchText: {
      type: String,
      default() {
        return ''
      },
    },
  },
  data() {
    return {
      editColActive: false,
      fields: [],
      items: [],
      tableFields: [],
      filteredItems: [],
      currentPage: 1,
      perPage: 10,
      itemsCount: 0,
      sortBy: 'retailer',
      currentPageCount: 0,
      isDesc: false,
      formattedDate,
    }
  },
  computed: {
    walletFields() {
      return getWalletFields()
    },
    referralFields() {
      return { key: 'referralId', label: 'ReferralId', variant: 'anchor-link' }
    },
    filterIncludedFiedls() {
      return this.tableFields.map(fields => fields.key)
    },
    tableIncludedFields() {
      // Removing fields which are not needed for the appropriate user
      if (this.isBrandTerms) {
        return ['value', 'retailer', 'accountType', 'discount']
      }
      if (this.isRetailerTerms) {
        return ['value', 'brand', 'commercialTerms', 'accountType', 'discount']
      }
      if (this.isPendingTerms) {
        return ['value', 'logisticsTerms', 'accountType', 'appliedTo', '_id']
      }
      return ['appliedTo', 'discount']
    },
    ruleTypeText() {
      if (this.isBrandTerms) {
        return 'Brand Rule'
      }
      if (this.isRetailerTerms) {
        return 'Retailer Rule'
      }
      if (this.isBrandVsRetailerTerms) {
        return 'Brand vs Retailer Rule'
      }
      return 'In Review Rule'
    },
  },
  created() {
    this.$root.$refs.walletTable = this
    this.setTableItems(this.tableData)
  },
  methods: {
    getTableFields(fields) {
      this.tableFields = fields.filter(
        field => !this.tableIncludedFields.includes(field.key),
      )
    },
    updatePerPage(value) {
      this.perPage = value
      this.getCurrentPageCount()
    },
    updateSort(value) {
      this.sortBy = value
    },
    updateSortDirection(value) {
      this.isDesc = value
    },
    getFilteredItems(items) {
      this.itemsCount = items.length
      this.filteredItems = items
      this.getCurrentPageCount()
    },
    async updateCurrentPage(value) {
      this.currentPage = value
      this.getCurrentPageCount()
    },
    getRetailerName(id) {
      return this.$store.getters.retailers.find(retailer => retailer.id === id)?.name || ''
    },
    getBrandName(id) {
      return this.$store.getters.brands.find(brand => brand.id === id)?.name || ''
    },
    setTableItems(items) {
      this.items = items
      this.getTableFields(this.walletFields)
      if (this.isPendingTerms) {
        this.tableFields.unshift(this.referralFields)
      }
      this.items.forEach(item => {
        item.retailer = this.getRetailerName(item.retailerId)
        item.brand = this.getBrandName(item.brandId)
        item.endDate = formattedDate(item.endTime)
        item.startDate = formattedDate(item.startTime)
        item.accountType = +item.orderCount > 0 ? 'Existing' : 'New'
        item.appliedTo = +item.orderCount > 0 ? 'Subsequent Orders' : 'New Order'
        item.priceBookId = item.priceBookId || null

        if (!this.isPendingTerms) {
          item.status = item.status ? 'Active' : 'Inactive'
        }
      })
      this.filteredItems = this.items
      this.itemsCount = this.items.length
      this.getCurrentPageCount()
    },
    getCurrentPageCount() {
      const totalCount = this.filteredItems.length
      const pageCount = totalCount / this.perPage
      if (this.currentPage <= pageCount) {
        this.currentPageCount = this.perPage
      } else {
        this.currentPageCount = Math.ceil(totalCount % this.perPage)
      }
      this.$emit('update-total-items', this.currentPageCount, this.itemsCount)
    },
    onClickEditTerms(data) {
      this.$store.commit(CLEAR_STATE)
      this.$store.commit(SET_TITLE, `Edit ${this.ruleTypeText}`)
      this.$store.commit(SET_SUB_TITLE, data.referralId)
      this.$store.commit(SET_COMPONENT, EditWalletRuleVue)
      this.$store.commit(SET_COMPONENT_CONTENT, data)
      this.$store.commit(SET_FORM_ACTIONS, this.emitActions)
      this.toggleSidebar()
    },
    toggleSidebar() {
      this.$store.commit(TOGGLE_SIDEBAR)
    },
    emitActions(action) {
      switch (action) {
        // when click cancel
        case 'cancel': {
          this.toggleSidebar()
          break
        }
        // When API returns success response
        case 'success': {
          this.$emit('load-wallet-rules')
          this.toggleSidebar()
          break
        }
        default: {
          break
        }
      }
    },
  },
  emits: ['update-total-items', 'load-wallet-rules', 'emit-sort-fields'],
}
</script>
