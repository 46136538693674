<template>
  <div class="create-wallet-padding">
    <validation-observer
      #default="{invalid}"
    >
      <div class="form-block pb-2">
        <k-form-group
          v-if="walletRuleData.brandId"
          label-content="Brand"
        >
          <k-form-input
            :value="walletRuleData.brand || walletRuleData.brandId"
            placeholder="Brand"
            disabled
          />
        </k-form-group>
        <k-form-group
          v-if="walletRuleData.retailerId"
          label-content="Retailer"
          class="mt-2"
        >
          <k-form-input
            :value="walletRuleData.retailer || walletRuleData.retailerId"
            placeholder="Retailer"
            disabled
          />
        </k-form-group>
        <validation-provider
          v-if="!isRetailerTerms"
          #default="{ errors }"
          name="Commercial Terms"
          vid="commercial"
          rules="required|between:0,100"
        >
          <k-form-group
            class="mt-2"
            :form-invalid-content="errors[0]"
            :state="errors.length > 0 ? false : null"
          >

            <template #label>
              <span>
                Commercial Terms <span
                  v-if="!isPendingTerms"
                  class="text-danger"
                >*</span>
              </span>
            </template>
            <k-form-input
              v-model="walletRuleData.commercialTerms"
              placeholder="Commercial Terms"
              :disabled="isPendingTerms"
            />
          </k-form-group>
        </validation-provider>
        <k-form-group
          v-if="isPendingTerms"
          class="mt-2"
          label-content="Discount"
        >
          <k-form-input
            :value="walletRuleData.discount || 'Discount'"
            name="Discount"
            disabled
          />
        </k-form-group>
        <validation-provider
          v-if="isBrandVsRetailerTerms"
          #default="{ errors }"
          name="Price Multiplier"
          vid="priceMultiplier"
          rules="required|between:0.1,1.8"
        >
          <k-form-group
            class="mt-2"
            :form-invalid-content="errors[0]"
            :state="errors.length > 0 ? false : null"
          >
            <template #label>
              <span>
                Price Multiplier <span class="text-danger">*</span>
              </span>
            </template>
            <k-form-input
              v-model="walletRuleData.value"
              name="priceMultiplier"
            />
          </k-form-group>
        </validation-provider>
        <k-form-group
          v-if="!isPendingTerms"
          class="mt-2"
        >
          <template #label>
            <span>
              Payment Terms <span class="text-danger">*</span>
            </span>
          </template>
          <b-dropdown
            v-if="walletRuleData.paymentTerms"
            class="w-100 k-search-dropdown dropdown-custom-class"
            :class="{'has-value': !!walletRuleData.paymentTerms}"
            variant="none"
            toggle-class="drop-caret-icon"
            :text="walletRuleData.paymentTerms ? walletRuleData.paymentTerms : 'Select Payment Terms'"
          >
            <b-dropdown-item
              v-for="terms in Object.values(PAYMENT_TERMS).map((_) => _.TEXT)"
              :key="terms"
              :active="walletRuleData.paymentTerms === terms"
              @click="walletRuleData.paymentTerms = terms"
            >
              {{ terms }}
            </b-dropdown-item>
          </b-dropdown>
        </k-form-group>
        <k-form-group
          v-if="!isPendingTerms"
          class="mt-2"
        >
          <template #label>
            <span>
              Logistics Terms <span class="text-danger">*</span>
            </span>
          </template>
          <b-dropdown
            class="w-100 k-search-dropdown dropdown-custom-class"
            :class="{'has-value': !!walletRuleData.logisticsTerms}"
            variant="none"
            toggle-class="drop-caret-icon"
            :text=" walletRuleData.logisticsTerms ?
              walletRuleData.logisticsTerms : 'Select Logistics Terms'"
          >
            <b-dropdown-item
              v-for="logistics in Object.values(LOGISTICS_TERMS)"
              :key="logistics"
              :active="walletRuleData.logisticsTerms === logistics"
              @click="walletRuleData.logisticsTerms = logistics"
            >
              {{ logistics }}
            </b-dropdown-item>
          </b-dropdown>
        </k-form-group>
        <k-form-group
          v-if="isBrandVsRetailerTerms"
          class="mt-2"
        >
          <template #label>
            <span>
              Price Book
            </span>
          </template>
          <b-dropdown
            class="w-100 k-search-dropdown dropdown-custom-class"
            :class="{'has-value': !!walletRuleData.priceBookId}"
            variant="none"
            toggle-class="drop-caret-icon"
            :text=" walletRuleData.priceBookId ?
              getPriceBookName(walletRuleData.priceBookId) : 'Select Price Book'"
          >
            <template v-if="priceBooks.length">
              <b-dropdown-item
                v-for="priceBook in priceBooks"
                :key="priceBook._id"
                :active="walletRuleData.priceBookId === priceBook._id"
                @click="walletRuleData.priceBookId = priceBook._id"
              >
                {{ `${priceBook.priceBookName} - ${priceBook.collectionName}` }}
              </b-dropdown-item>
            </template>
            <b-dropdown-item
              v-else
              disabled
            >
              No price book available
            </b-dropdown-item>
          </b-dropdown>
        </k-form-group>
        <k-form-group
          v-if="!isPendingTerms"
          class="mt-2"
        >
          <template #label>
            <span>
              {{ isBrandVsRetailerTerms ? "Account Type" : "Applied To" }}<span class="text-danger">*</span>
            </span>
          </template>
          <div class="radio-block">
            <b-form-radio
              v-model="walletRuleData.orderCount"
              value="0"
              class="form-radio text-nowrap me-5 "
            > {{ isBrandVsRetailerTerms ? 'New': 'New Orders' }} </b-form-radio>
            <b-form-radio
              v-model="walletRuleData.orderCount"
              value="1000"
              class="form-radio text-nowrap"
            >{{ isBrandVsRetailerTerms ? 'Existing': 'Subsequent Orders' }} </b-form-radio>
          </div>
        </k-form-group>
        <k-form-group
          v-if="!isPendingTerms"
          class="mt-2"
        >
          <template #label>
            <span> Start Date (Optional) </span>
          </template>
          <k-form-input
            v-model="walletRuleData.startDate"
            type="date"
            class="form-input-text"
            :class="{'has-value': !!walletRuleData.startDate}"
          />
        </k-form-group>
        <k-form-group
          v-if="!isPendingTerms"
          class="mt-2"
        >
          <template #label>
            <span> End Date (Optional) </span>
          </template>
          <k-form-input
            v-model="walletRuleData.endDate"
            type="date"
            class="form-input-text"
            :class="{'has-value': !!walletRuleData.endDate}"
          />
        </k-form-group>
        <k-form-group v-if="isBrandVsRetailerTerms && isSelfManagedBrand">
          <div class="pt-3 pb-2 ps-1 d-flex flex-row align-items-center">
            <span>
              <label
                class="custom-checkbox-container"
              >
                <input
                  v-model="walletRuleData.isSelfManaged"
                  type="checkbox"
                >
                <span class="checkmark" />
              </label>
            </span>
            <span
              class="ps-4 cursor-pointer"
              @click="walletRuleData.isSelfManaged=!walletRuleData.isSelfManaged"
            >Enable Kingpin Direct</span>
          </div>
        </k-form-group>
        <k-form-group
          v-if="isPendingTerms"
          class="mt-1"
          label-content="Proof of Orders"
        >
          <k-form-input
            :value="walletRuleData.proofOfOrders || ''"
            placeholder="Proof Of Orders"
            disabled
            class="pe-5"
          />
          <kingpin-icon
            v-if="walletRuleData.proofOfOrders"
            icon="DownloadCloudIcon"
            class="input-append-icon-position"
            @click="download(walletRuleData.proofOfOrders)"
          />
        </k-form-group>
        <k-form-group
          v-if="isPendingTerms"
          label-content="Status"
          class="mt-2"
        >
          <b-dropdown
            class="w-100 k-search-dropdown dropdown-custom-class"
            :class="{ 'has-value': !!selectedStatus }"
            variant="none"
            toggle-class="drop-caret-icon"
            :text="selectedStatus ? selectedStatus : 'Select Status'"
          >
            <b-dropdown-item
              v-for="status in Object.values(REFERRAL_WALLETRULE_STATUS)"
              v-show="status !== REFERRAL_WALLETRULE_STATUS.PENDING"
              :key="status"
              class="text-capitalize"
              :active="selectedStatus === status"
              @click="selectedStatus = status"
            >
              {{ status }}
            </b-dropdown-item>
          </b-dropdown>
        </k-form-group>
      </div>
      <div class="d-flex align-items-center mt-3">
        <k-button
          v-if="isPendingTerms"
          variant="info"
          type="submit"
          block
          :disabled="loading || isUpdateDisabled"
          @click="updatePendingRule"
        >
          UPDATE
          <loader-icon v-if="loading" />
        </k-button>
        <k-button
          v-else
          variant="info"
          type="submit"
          block
          :disabled="loading || invalid"
          @click="updateWalletRule"
        >
          UPDATE
          <loader-icon v-if="loading" />
        </k-button>
        <k-button
          variant="outline-info"
          class="ms-4"
          block
          @click="$emit('dynamic-emits', 'cancel')"
        >
          CANCEL
        </k-button>
      </div>
    </validation-observer>
  </div>
</template>
<script>
import { formatDateForInput } from '@/@core/utils/format'
import { apiToastError, apiToastSuccess } from '@/@core/utils/toast'
import { download } from '@/common-utils'
import { walletMixin } from '@/mixins/wallet-mixin'
import store from '@/store'
import { GET_PRICE_BOOK } from '@/store/modules/collection.module'
import { FETCH_USER } from '@/store/modules/user-module'
import { UPDATE_REFERRAL_WALLET_RULE_STATUS, UPDATE_WALLET_RULE } from '@/store/modules/wallet.module'
import {
  KButton, KFormGroup, KFormInput, KingpinIcon,
} from '@kingpin-global/kingpin-ui'
import { constants } from '@kingpin-global/kingpin-utils-frontend'
import {
  BDropdown, BDropdownItem, BFormRadio,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { LoaderIcon } from 'vue-feather-icons'

const {
  BRAND_ACCESS,
  LOGISTICS_TERMS, PAYMENT_TERMS, REFERRAL_WALLETRULE_STATUS,
} = constants

export default {
  components: {
    BDropdown,
    BDropdownItem,
    LoaderIcon,
    KButton,
    KFormGroup,
    KFormInput,
    KingpinIcon,
    ValidationProvider,
    ValidationObserver,
    BFormRadio,
  },
  mixins: [walletMixin],
  props: {
    componentContent: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      walletRuleData: JSON.parse(JSON.stringify(this.componentContent)),
      selectedStatus: this.componentContent?.status,
      selectedLogisticsTerms: '',
      loading: false,
      REFERRAL_WALLETRULE_STATUS,
      download,
      LOGISTICS_TERMS,
      PAYMENT_TERMS,
      selectedPaymentTerms: null,
      isSelfManagedBrand: false,
      priceBooks: [],
    }
  },
  computed: {
    isUpdateDisabled() {
      return this.selectedStatus === this.componentContent.status
    },
  },
  created() {
    if (this.walletRuleData?.startDate) {
      this.walletRuleData.startDate = formatDateForInput(this.walletRuleData.startDate)
    }
    if (this.walletRuleData?.endDate) {
      this.walletRuleData.endDate = formatDateForInput(this.walletRuleData.endDate)
    }
    if (this.isBrandVsRetailerTerms && this.walletRuleData?.brandId) {
      store.dispatch(FETCH_USER, this.walletRuleData.brandId).then(res => {
        const resData = res?.data?.data
        this.isSelfManagedBrand = resData?.access?.includes(BRAND_ACCESS.DIRECT) || false
      })
    }
    if (this.isBrandVsRetailerTerms) {
      this.getPriceBook()
    }
  },
  emits: ['dynamic-emits'],
  methods: {
    updatePendingRule() {
      this.loading = true
      const payload = {
        walletRule: {
          status: this.selectedStatus,
        },
      }
      store
        .dispatch(UPDATE_REFERRAL_WALLET_RULE_STATUS, { referralId: this.walletRuleData.referralId, payload })
        .then(res => {
          this.loading = false
          apiToastSuccess(res.data.message)
          this.$emit('dynamic-emits', 'success')
        })
        .catch(err => {
          this.loading = false
          apiToastError(err)
        })
    },
    updateWalletRule() {
      this.loading = true
      const walletRuleId = this.walletRuleData._id
      const payload = {
        logisticsTerms: this.walletRuleData.logisticsTerms,
        orderCount: Number(this.walletRuleData.orderCount),
        paymentTerms: this.walletRuleData.paymentTerms,
      }
      if (this.walletRuleData?.endDate) {
        payload.endTime = new Date(this.walletRuleData.endDate)
      }
      if (this.walletRuleData?.startDate) {
        payload.startTime = new Date(this.walletRuleData.startDate)
      }
      if (this.isBrandVsRetailerTerms || this.isBrandTerms) {
        payload.commercialTerms = Number(this.walletRuleData.commercialTerms)
      }
      if (this.isBrandVsRetailerTerms) {
        payload.value = Number(this.walletRuleData.value)
        if (this.walletRuleData?.isSelfManaged) {
          payload.isSelfManaged = this.walletRuleData.isSelfManaged
        }
        if (this.walletRuleData?.priceBookId) {
          payload.priceBookId = this.walletRuleData.priceBookId
        }
      }
      store.dispatch(UPDATE_WALLET_RULE, { walletRuleId, payload }).then(res => {
        this.loading = false
        apiToastSuccess(res.data.message)
        this.$emit('dynamic-emits', 'success')
      })
        .catch(err => {
          this.loading = false
          apiToastError(err)
        })
    },
    async getPriceBook() {
      try {
        if (!this.walletRuleData?.brandId) {
          return
        }
        const params = {
          brandId: this.walletRuleData?.brandId,
        }
        const result = await this.$store.dispatch(GET_PRICE_BOOK, params)
        this.priceBooks = result?.data?.data || []
      }
      catch (err) {
        apiToastError(err)
      }
    },
  },
}
</script>
